import './App.less'
import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import routes from './route'
import { useEffect } from 'react'
function App() {
  const location = useLocation()
  const whitelist = ['/login', '/forget-password', '/sign-up','/sign-up-success','/sign-up-investor', '/reset-password']
  const navigate = useNavigate()
  useEffect(() => {
    // console.log(location.pathname, "app.js，全局路由守卫、enter")
    let env = process.env.NODE_ENV
    console.log(env)
    // 不在白名单内验证token
    if (!whitelist.includes(location.pathname)) {
      if (!window.sessionStorage.getItem('elseAdminToken')) {
        console.log('拦截？？')
        return navigate('/login')
      }
      return () => {
        // console.log(location.pathname, "app.js，全局路由守卫、leave")
      }
    }
  }, [location.pathname])
  return useRoutes(routes)
}
export default App
